import { newAxiosIns } from '@/libs/axios'

export function apiGetSubsPlan() {
  return newAxiosIns.get('komship/api/v1/whatsapp/subscription/plan')
}

export function apiSubs(id) {
  const params = {
    plan_id: id,
  }
  return newAxiosIns.post('komship/api/v1/whatsapp/subscribe', params)
}

export function apiConnectFb(phone_number_id, waba_id, code) {
  const params = {
    phone_number_id,
    waba_id,
    code,
  }
  return newAxiosIns.post('komchat-api/api/v1/channel/connect', params)
}

export function apiListDataNumber() {
  return newAxiosIns.get('komchat-api/api/v1/channel/list')
}

export function apiTopupKomchatCoin(nominal, channel_id) {
  return newAxiosIns.post('auth/api/v1/komchat/channel/topup', { nominal, channel_id })
}
export function apiSetTwoFactor(pin, channel_id) {
  return newAxiosIns.post('komchat-api/api/v1/channel/set-two-factor', { pin, channel_id })
}
export function apiDeleteChannel(channel_id) {
  return newAxiosIns.delete(`/komchat-api/api/v1/channel/delete/${channel_id}`)
}
